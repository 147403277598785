import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
/* Warning: please do not statically import any dependencies here except for static assets like icons */
import { HomeIcon, DashboardIcon, ChartBarIcon, DataIcon, SettingsIcon, } from "@panwds/icons";
import { lazy, Suspense } from "react";
import "./global.scss";
import { history } from "@sparky/framework";
//const INSTANCE_STATUS_RUNNING = 'running';
/**
 *
 * SASE IA Micro App Covers the following use cases
 * Use Case 1: Provide a stitched view for tab level integration with routes, TabListContainer
 * Use Case 2: Provide a Tenant vs Sub Tenant Selector for GCS vs Customer App
 * Use Case 3: Provide tenancy information in case of stitched or PAI exposed components
 *
 */
const loadApp = async () => {
    const CanvasAI = lazy(async () => import("./canvas-ai"));
    return {
        default: () => (_jsx(Suspense, { fallback: _jsx(_Fragment, { children: "loading..." }), children: _jsx(CanvasAI, { href: location.href, dependencies: { history } }) })),
    };
};
export default async () => {
    // console.info(
    //     `%c${pkg.name}: ${pkg.version}`,
    //     'font-size: 14px; color: white; background-color: black; border-radius: 8px; padding: 10px 15px;',
    // );
    return async () => {
        //sparkyFramework.actions.setValues({dataSpaceStudioGlobalState:{}});
        // Check for GCS vs Customer App
        //const frameworkVars = sparkyFramework.getFrameworkVars();
        //const { private_site_origin } = frameworkVars;
        //const isPrivateApp = Boolean(private_site_origin);
        // The following function will take care of three things
        // 1. Instantiate the initial values required in the API/Network Manager Singletons
        // 2. Set initial values for dataSpaceStudioGlobalState
        // 3. Set the tenancy information for both GCS vs Customer App
        //const { fetchTenantFeatures } = await import("./fetchTenantFeatures");
        //const promise = fetchTenantFeatures({ sparkyFramework, auth, main, isPrivateApp});
        // Initialize dashboards and fetch micro app license info
        /*(if (!isPrivateApp) {
                const { fetchMicroAppLicense } = await import('./fetchMicroAppLicense');
                fetchMicroAppLicense();
            }*/
        //await write to sparky state before sending navigation (left hand nav)
        /* await sparkyFramework.dedupPromise("navigationSync", () => new Promise(async (resolve, reject) => {
                try {
                    const result = await promise;
                    sparkyFramework.actions.setValues({ dataSpaceStudioMainState: { cosmosFeatures: result?.data }});
                    resolve();
                } catch(e) {
                  reject(e);
                }}), { keepPromiseIn: 60000 });*/
        return {
            navigation: [
                {
                    key: "home",
                    title: "Home",
                    component: () => loadApp(),
                    icon: HomeIcon,
                    path: "/",
                    mergeNav: true,
                    noRBACheck: true,
                    contentClassName: "dataspace-studio-client-root-home panwds-tw3",
                },
                {
                    key: "dashboards",
                    title: "Dashboards",
                    component: () => loadApp(),
                    icon: DashboardIcon,
                    path: "/dashboards",
                    mergeNav: true,
                    noRBACheck: true,
                    contentClassName: "dataspace-studio-client-root-dashboard panwds-tw3",
                },
                {
                    key: "catalog",
                    title: "Catalog",
                    component: () => loadApp(),
                    icon: ChartBarIcon,
                    path: "/catalog",
                    mergeNav: true,
                    noRBACheck: true,
                    contentClassName: "dataspace-studio-client-root-catalog panwds-tw3",
                },
                {
                    key: "canvas",
                    title: "Canvas",
                    component: () => loadApp(),
                    icon: ChartBarIcon,
                    path: "/canvas",
                    mergeNav: true,
                    noRBACheck: true,
                    contentClassName: "dataspace-studio-client-root-canvas panwds-tw3",
                },
                {
                    key: "widgets",
                    title: "Widgets",
                    component: () => loadApp(),
                    icon: DataIcon,
                    path: "/widgets",
                    mergeNav: true,
                    noRBACheck: true,
                    contentClassName: "dataspace-studio-client-root-widgets panwds-tw3",
                },
                {
                    key: "settingsLocal",
                    title: "Settings",
                    component: () => loadApp(),
                    icon: SettingsIcon,
                    path: "/settings",
                    mergeNav: false,
                    noRBACheck: true,
                    contentClassName: "dataspace-studio-client-root-settings panwds-tw3",
                },
            ],
            /*exposes: {
                      default: {
                          TenantFetcher: () => import('./TenantFetcherWrapper'),
                          TenantSelector: () => import('./TenantSelectorWrapper'),
                          SubTenantSelector: () => import('./SubTenantSelectorWrapper'),
                          GlobalSearch: () => import('./GlobalSearchWrapper')
                      },
                  },*/
        };
    };
};
